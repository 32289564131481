.account-ledger {
    color: black;
    font-weight: 700;
}

.d-flex1 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.input-party-name {
    width: 200px;
}

.search-party {
    background: #436ad8;
    color: #fff;
    padding: 1px 5px;
}

.closing-balance {
    font-weight: 500;
    margin-left: 4px;
}

.outer-div {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.inner-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 150px;
    flex: 1 1 1;
    min-height: 50px;
}

.inner-div span {
    min-height: 1.5rem;
    display: inline-block;
}

.search-results {
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid rgb(204, 204, 204);
    margin-top: -5px;
    border-top: none;
}
.search-results2 {
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid rgb(204, 204, 204);
    margin-top: -5px;
    border-top: none;
    width: 200px;
}

.select-tns-type{
    height: 30px;
}

@media (max-width: 768px) {
    .outer-div {
        flex-direction: row;
        gap: 10px;
        margin: 0 10px;
    }

    .inner-div {
        min-width: 150px;
        flex: 1 1 0;
    }

    button {
        align-self: flex-start;
        width: auto;
    }

    .d-flex1 {
        flex-direction: column;
        gap: 15px;
    }

    .row {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .input-party-name {
        width: 100%;
        margin-right: 0;
    }

    .search-party {
        margin-top: 5px;
        width: 100%;
    }

    .closing-balance {
        font-size: 1.2rem;
        text-align: left;
    }
}
