.select-withdrawal {
    width: 100px;
}

.ver {
    vertical-align: middle;
}

.withdraw-select {
    width: 150px;
}

.withdraw-approved-clr {
    background-color: #84d184 !important;
    color: black;
}

.withdraw-pending-clr {
    background-color: #fff !important;
    color: black;
}

.withdraw-rejected-clr {
    background-color: #ff7e7e !important;
    color: black;
}
.withdraw-onhold-clr {
    background-color: #f6d08b !important;
    color: black;
}
.withdraw-reverse-clr {
    background-color: #f2f2ad !important;
    color: black;
}