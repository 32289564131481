.user-report-button {
    background-color: #007BFF;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.user-report-button-text {
    margin-bottom: 0;
    color: white;
    font-size: 14px;
}