.user-detail-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
}

.user-detail-card-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
}

.user-detail-table-container {
    margin: 10px 0;
}