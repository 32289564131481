.dwcard {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.card-dw {
    background: #eee;
    border-radius: 15px;
}

.title-name {
    font-size: 0.9rem;
}

.dw-card {
    height: 10rem;
}

.approved {
    background: green;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
}
.pending {
    background: yellow;
    color: white;
    padding: 0 5px;
    border-radius: 5px;
}
