.user-report-card {
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;
    background-color: white;
    border-radius: 1rem;
    border-width: 0;
    margin: 8px;
    width: 260px;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
}

.user-report-card-title-text {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 15px;
}

.user-report-card-value-text {
    margin-bottom: 0;
    font-size: 15px;
}