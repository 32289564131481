.text-inp{
    width: 300px;
}
.parent-container {
    text-align: center;
}

.centered-div {
    display: inline-block;
    text-align: left;
}