.MuiSelect-selectMenu {
    padding: 6px !important;
}

.flow-off {
    overflow: hidden !important;
}

.lbl {
    font-size: 12px;
}

.approved-clr {
    background-color: #84d184 !important;
    color: black;
}

.pending-clr {
    background-color: #fff !important;
    color: black;
}

.rejected-clr {
    background-color: #ff7e7e !important;
    color: black;
}

@media (max-width: 1200px) {
    .sechBtn {
        /* width: 85px !important; */
        font-size: 12px;
    }
    .lbl {
        font-size: 9px;
    }
}