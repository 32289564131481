@media screen and (max-width: 1200px) {
  .signot {
    margin-right: 30px;
  }
  .prof {
    margin-right: -50px;
  }
}

.passmatch {
  color: red;
  font-size: 10px;
  margin-bottom: 0;
  margin-top: 15px;
}
